import React from "react";
import "./style.css";

const Progress = () => {
  return (
    <>
      <div className="progress">
        <div className="progress-outer">
          <div className={`progress "progress--small progress--loading`}>
            <div className={`progress-bar`} style={{ width: "50%" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Progress;
