import React from 'react';

const KatBookView = React.lazy(() => import('./views/KatBookView/KatBookView'));

const Book = React.lazy(() => import('./views/KBView/KBView'));

const TeacherView = React.lazy(() => import('./views/TeacherView/TeacherView'));

// const HolidayMasterEmployee = React.lazy(() => import('./views/HolidayMaster/HolidayEmployee'));
const StaffIndex = React.lazy(() => import('./views/StaffMapping/StaffIndex'));

// const HolidayMaster = React.lazy(() => import('./views/HolidayMaster/HolidayMaster'))
const Dashboard = React.lazy(()=> import('./views/katbookInstitation/KatBookInstition'))

const BookListForST =   React.lazy(()=> import('./views/KBView/BookListForST.js'));

const lzwcompress =   React.lazy(()=> import('./views/KBView/lzwcompress.js'));

const LinkContentView = React.lazy(()=>import('./views/ContentLink/ContentLinkView'));

const KatBookCreation = React.lazy(() => import('./views/BookCreation/BookCreation'));

const routes = [
  // Dashboard

  { path: '/', exact: true, name: 'Home', component: Dashboard, },

  { path: '/KatBookView', exact: true, name: 'Katbook Creation / Katbook (Published)', component: KatBookView },
  { path: '/BookView/:bookId/:book/:sId', exact: true, name: 'Book View', component: Book },
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID/:refMediumID', exact: true, name: 'Kampus Book View', component: Book, kampus: true },
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID', exact: true, name: 'Kampus Book View', component: Book },
  // {path:'/BookView/Content/:company/:country/:inst/:book/:obj/:crumb', exact: true, name: 'Book View Content', component:Book},
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID', exact: true, name: 'Kampus Book View', component: Book },

];

export default routes;
